<template>
  <div class="step-wrapper">
    <div class="pagination">
      <div class="prev-nav-button">
        <prev-button-layout @click="prevStep" v-show="stepIndex > 0"></prev-button-layout>
      </div>
      <div class="next-nav-button">
        <next-button-layout @click="nextStep" v-show="!isLastStep"></next-button-layout>
      </div>
    </div>
    <Transition name="fade" mode="out-in">
      <component :key="currentStep.id" :is="currentStepComponent"></component>
    </Transition>
  </div>

  <div class="pagination">
    <div class="prev-nav-button">
      <prev-button-layout @click="prevStep" v-show="stepIndex > 0"></prev-button-layout>
    </div>
    <p class="page-index">
      {{ stepIndex + 1 }} / {{ steps.length }}
    </p>
    <div class="next-nav-button">
      <next-button-layout @click="nextStep" v-show="!isLastStep"></next-button-layout>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import PrevButtonLayout from '@/components/buttons/PrevButtonLayout.vue'
import NextButtonLayout from '@/components/buttons/NextButtonLayout.vue'

export default {
  name: 'CoproCarousel',
  components: { NextButtonLayout, PrevButtonLayout },
  data () {
    return {
      stepIndex: 0,
      steps: [
        {
          id: 0,
          component: async () => await import('./copro-examples/Example1.vue')
        },
        {
          id: 1,
          component: async () => await import('./copro-examples/Example2.vue')
        }
      ]
    }
  },
  computed: {
    isLastStep () {
      return this.stepIndex >= this.steps.length - 1
    },
    currentStep () {
      return this.steps[this.stepIndex]
    },
    currentStepComponent () {
      return defineAsyncComponent(this.currentStep.component)
    }
  },
  methods: {
    nextStep () {
      this.stepIndex++
    },
    prevStep () {
      this.stepIndex--
    }
  }
}
</script>

<style scoped lang="scss">
  .block-4 {
    .pagination:first-child {
      grid-template-columns: unset;
      margin-top: 0;
      gap: 0;
    }
  }
</style>
