<template>
  <step-lesson-layout :title="$t('module3.part2.lesson3.title')">
    <template v-slot:content>
      <div class="grid-wrapper">
        <!-- BLOCK 1 -->
        <div class="lesson-block block1">
          <img class="copro-img" :src="require('@/assets/logos/aides/logo-mpr-copro.svg')" :alt="$t('global.imageAlts.logoMprCopro')">
          <br>
          <p class="paragraphe">{{ $t('module3.part2.lesson3.block1.content') }}</p>
        </div>

        <!-- BLOCK 2 -->
        <div class="lesson-block block-2">
          <p class="title">{{ $t('module3.part2.lesson3.block2.title') }}</p>
          <p class="paragraphe">{{ $t('module3.part2.lesson3.block2.content') }}</p>
          <div class="check-list">
            <app-icon-block type="check" :content="$t('module3.part2.lesson3.block2.check1')">
              <template v-slot:content>
                <p class="checklist-title">{{ $t('module3.part2.lesson3.block2.check1.title1') }}</p>
                <p class="checklist-content">{{ $t('module3.part2.lesson3.block2.check1.content1') }}</p>
                <br>
                <p class="checklist-title">{{ $t('module3.part2.lesson3.block2.check1.title2') }}</p>
                <p class="checklist-content">{{ $t('module3.part2.lesson3.block2.check1.content2') }}</p>
              </template>
            </app-icon-block>
            <app-icon-block type="check">
              <template v-slot:content>
                <p class="checklist-content">{{ $t('module3.part2.lesson3.block2.check2.content1') }}</p>
                <br>
                <p class="checklist-title">{{ $t('module3.part2.lesson3.block2.check2.title2') }}</p>
                <p class="checklist-content">{{ $t('module3.part2.lesson3.block2.check2.content2') }}</p>
              </template>
            </app-icon-block>
            <app-icon-block type="check" :content="$t('module3.part2.lesson3.block2.check3')"></app-icon-block>
            <app-icon-block type="check" :content="$t('module3.part2.lesson3.block2.check4')"></app-icon-block>
            <app-icon-block type="check" :content="$t('module3.part2.lesson3.block2.check5')"></app-icon-block>
          </div>
        </div>

        <!-- BLOCK 3 -->
        <div class="lesson-block block-3">
          <p class="title">{{ $t('module3.part2.lesson3.block3.title') }}</p>
          <p class="paragraphe" v-html="$t('module3.part2.lesson3.block3.intro')"></p>

          <!-- 35 à 50% -->
          <p class="sub-title">{{ $t('module3.part2.lesson3.block3.montant1.title') }}</p>
          <div class="flex-wrapper montant-wrapper">
            <img :src="require('@/assets/module3/part2/illu-money.svg')" :alt="$t('global.imageAlts.moneyIllu')">
            <div class="montant">
              <p class="label">{{ $t('module3.part2.lesson3.block3.montant1.montant') }}</p>
              <p class="annotation">{{ $t('module3.part2.lesson3.block3.montant1.annotation') }}</p>
            </div>
          </div>

          <!-- + de 50% -->
          <p class="sub-title">{{ $t('module3.part2.lesson3.block3.montant2.title') }}</p>
          <div class="flex-wrapper montant-wrapper">
            <img :src="require('@/assets/module3/part2/illu-money.svg')" :alt="$t('global.imageAlts.moneyIllu')">
            <div class="montant">
              <p class="label">{{ $t('module3.part2.lesson3.block3.montant2.montant') }}</p>
              <p class="annotation">{{ $t('module3.part2.lesson3.block3.montant2.annotation') }}</p>
            </div>
          </div>

          <!-- Section bonification montant -->
          <div class="separated-content">
            <p class="paragraphe">{{ $t('module3.part2.lesson3.block3.section2.intro') }}</p>
            <div class="conditions-block">
              <div class="conditions">
                <div class="condition">
                  <img :src="require('@/assets/module3/part2/ico-gain-classe.svg')">
                  <div>
                    <p><b>{{ $t('module3.part2.lesson3.block3.section2.item1Content') }}</b></p>
                    <p class="annotation">{{ $t('module3.part2.lesson3.block3.section2.item1Annotation') }}</p>
                  </div>
                </div>
                <div class="condition">
                  <img :src="require('@/assets/module3/part2/logo-mpr-jaune.svg')">
                  <div>
                    <p><b>{{ $t('module3.part2.lesson3.block3.section2.item2Content') }}</b></p>
                    <p class="annotation">{{ $t('module3.part2.lesson3.block3.section2.item2Annotation') }}</p>
                  </div>
                </div>
                <div class="condition">
                  <img :src="require('@/assets/module3/part2/logo-mpr-bleu.svg')">
                  <div>
                    <p><b>{{ $t('module3.part2.lesson3.block3.section2.item3Content') }}</b></p>
                    <p class="annotation">{{ $t('module3.part2.lesson3.block3.section2.item3Annotation') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Section cumul -->
          <div class="separated-content">
            <p class="sub-title">{{ $t('module3.part2.lesson3.block3.section3.title') }}</p>
            <p class="paragraphe">
              {{ $t('module3.part2.lesson3.block3.section3.content') }}
              <b>
                {{ $t('module3.part2.lesson3.block3.section3.contentBold') }}
              </b>
            </p>
          </div>
        </div>

        <div class="lesson-block block-4">
         <CoproCarousel />
        </div>
      </div>

      <!-- END BLOCK -->
      <div class="app-decoration-block end-block">
        <p class="decoration-block-content">
          {{ $t('training.global.partFinished') }}
        </p>
        <app-button-layout @click="goToNextStep">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
        <app-button-layout color="alternate-secondary" @click="resumeLater">
          {{ $t('global.navigation.resumeLater') }}
        </app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import useTrainingUtils from '@/utils/useTrainingUtils'
import AppIconBlock from '@/components/layout/AppIconBlock'
import CoproCarousel from '@/components/training/module3/part2/CoproCarousel.vue'

export default {
  name: 'Module3Part2Lesson3',
  components: { CoproCarousel, AppIconBlock, StepLessonLayout, AppButtonLayout },
  setup (props, context) {
    const { resumeLater, goToNextStep } = useTrainingUtils()
    return { resumeLater, goToNextStep }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../styles/module3Lesson";

.copro-img {
  max-width: 100%;
  margin-bottom: $space-sm;
}

.montant {
  max-width: 230px;
  .label {
    border-radius: 80px;
    background-color: rgba(#b5c84e, .4);
    padding: $space-s $space-ml;
    text-align: center;
    font-weight: $fw-l;
    color: $c-text-dark;
  }
  .annotation {
    margin-top: $space-xxs;
    color: $c-text-dark;
    font-size: $fz-xs;
    font-weight: $fw-m;
  }
}

.flex-wrapper {
  &.montant-wrapper {
    justify-content: center;
  }
}

.block-2 .check-list {
  .checklist-title {
    text-transform: uppercase;
    color: $c-text-darker;
    font-size: $fz-xs;
    font-weight: $fw-l;
    margin-bottom: $space-xs;
  }
  .checklist-content {
    font-weight: $fw-m;
    font-size: $fz-s;
  }
  .app-icon-block:deep(.content) {
    p b {
      font-weight: $fw-m;
    }
  }
}

.block-3 .conditions-block {
  .condition {
    font-size: $fz-s;
    gap: $space-sm;
    img {
      width: 50px;
      flex-shrink: 0;
    }
  }
}

@media (min-width: $bp-tablet) {
  .grid-wrapper {
    .lesson-block + .lesson-block {
      margin: 0;
    }
    display: grid;
    gap: $space-s;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
    .block1 {
      grid-column-start: span 2;
    }
    .block-3, .block-4 {
      grid-row: span 2;
    }
  }
}
</style>
